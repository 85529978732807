<template>
	<div class="page-web page-home">
		<Head />
		<div class="index-banner">
			<div class="container">
				<div class="index-grid flex">
					<div class="grid-photo">
					<el-card class="box-card">
						<h1 class="shake-chunk" style="font-size: 40px;">{{$t('indexTxt3')}}</h1>
						<div style="font-size: 26px;"><count-down :endTime="endTime" :endText="endText "/></div>
					</el-card>
					</div>
					<div class="flex_bd">
						<div class="grid-title" id="element">{{$t('indexTxt1')}}</div>
						<div class="grid-desc"></div>
					</div>
				</div>
				<div class="index-attr flex">
					<div @click="toUrl('/exchange/BTCUSDT')" class="wow fadeInUp animated col hvr-wobble-top" data-wow-duration="1s" data-wow-delay="0.2s">
						<h3 class="shake-chunk">{{$t('indexLabel1')}}</h3>
						<p>{{$t('indexVal1')}}</p>
					</div>
					<div class="line"></div>
					<div @click="toUrl('/wallet')" class="wow fadeInUp animated col hvr-wobble-horizontal" data-wow-duration="1s" data-wow-delay="0.3s">
						<h3>{{$t('indexLabel2')}}</h3>
						<p>{{$t('indexVal2')}}</p>
					</div>
					<div class="line"></div>
					<div @click="toUrl('/mining')" class="wow fadeInUp animated col hvr-wobble-vertical" data-wow-duration="1s" data-wow-delay="0.4s">
						<h3>{{$t('indexLabel3')}}</h3>
						<p>{{$t('indexVal3')}}</p>
					</div>
					<div class="line"></div>
					<div @click="toUrl('/inviteusers')" class="wow fadeInUp animated col hvr-wobble-bottom" data-wow-duration="1s" data-wow-delay="0.5s">
						<h3>{{$t('indexLabel4')}}</h3>
						<p>{{$t('indexVal4')}}</p>
					</div>
				</div>
			</div>
		</div>
		<div class="index-section" v-show="windowWidth">
			<div class="container">
				<div class="index-tab-main">
					<el-tabs v-model="activeName">
						<el-tab-pane :label="$t('market')" name="USDT">
							<div class="bz-table">
								<el-table :data="tickers" style="width: 100%" :empty-text="$t('Internet')" >
									<el-table-column prop="isFavor" width="60">
										<template slot-scope="scope">
											<el-button type="text" class="favor-btn"
												@click="delFavor(scope.row,scope.$index)" v-if="scope.row.isFavor">
												<span class="iconfont icon-star"></span>
											</el-button>
											<el-button @click="addFavor(scope.row)" type="text" class="favor-btn"
												v-else>
												<span class="iconfont icon-star_off"></span>
											</el-button>
										</template>
									</el-table-column>
									<el-table-column width="120" prop="symbol" :label="$t('czTxt2')">
									</el-table-column>
									<el-table-column width="150" prop="lastPrice" :label="$t('mth1')">
									</el-table-column>
									<el-table-column width="120" prop="priceChangePercent" :label="$t('mth2')">
										<template slot-scope="scope">
											<span class="text-green" v-if="scope.row.priceChangePercent > 0">+{{scope.row.priceChangePercent}}%</span>
											<span class="text-green" v-if="scope.row.priceChangePercent == 0">{{scope.row.priceChangePercent}}%</span>
											<span class="text-red" v-if="scope.row.priceChangePercent < 0">{{ scope.row.priceChangePercent}}%</span>
										</template>
									</el-table-column>
									<el-table-column width="150" prop="highPrice" :label="$t('mTxt')">
									</el-table-column>
									<el-table-column width="150" prop="lowPrice" :label="$t('mTxt2')">
									</el-table-column>
									<el-table-column width="150" prop="volume" :label="$t('mTxt3')">
									</el-table-column>
									<el-table-column width="220" prop="quoteVolume" :label="$t('indexth')">
									</el-table-column>
									<el-table-column :label="$t('th5')">
										<template slot-scope="scope">
											<el-button type="text" @click="toExchange(scope.row.symbol)">
												{{$t('indexbtn')}}
											</el-button>
										</template>
									</el-table-column>
								</el-table>
							</div>
						</el-tab-pane>
						
					</el-tabs>
				</div>
			</div>
		</div>
		<div class="index-section"  v-show="!windowWidth">
			<div class="container">
				<div class="index-tab-main">
					<el-tabs v-model="activeName">
						<el-tab-pane :label="$t('market')" name="USDT">
							<div class="bz-table">
								<el-table :empty-text="$t('Internet')" 
								:data="tickers" style="width: 100%">
									<!-- <el-table-column prop="isFavor" width="60">
										<template slot-scope="scope">
											<el-button type="text" class="favor-btn"
												@click="delFavor(scope.row,scope.$index)" v-if="scope.row.isFavor">
												<span class="iconfont icon-star"></span>
											</el-button>
											<el-button @click="addFavor(scope.row)" type="text" class="favor-btn"
												v-else>
												<span class="iconfont icon-star_off"></span>
											</el-button>
										</template>
									</el-table-column> -->
									<el-table-column width="80" prop="symbol" :label="$t('czTxt2')">
									</el-table-column>
									<el-table-column width="120" prop="lastPrice" :label="$t('mth1')">
									</el-table-column>
									<el-table-column width="80" prop="priceChangePercent" :label="$t('mth2')">
										<template slot-scope="scope">
											<span class="text-green" v-if="scope.row.priceChangePercent > 0">+{{scope.row.priceChangePercent}}%</span>
											<span class="text-green" v-if="scope.row.priceChangePercent == 0">{{scope.row.priceChangePercent}}%</span>
											<span class="text-red" v-if="scope.row.priceChangePercent < 0">{{ scope.row.priceChangePercent}}%</span>
										</template>
										</el-table-column>
									<!--<el-table-column width="150" prop="highPrice" :label="$t('mTxt')">
									</el-table-column>
									<el-table-column width="150" prop="lowPrice" :label="$t('mTxt2')">
									</el-table-column>
									<el-table-column width="150" prop="volume" :label="$t('mTxt3')">
									</el-table-column>
									<el-table-column width="220" prop="quoteVolume" :label="$t('indexth')">
									</el-table-column> -->
									<el-table-column :label="$t('th5')">
										<template slot-scope="scope">
											<el-button type="text" @click="toExchange(scope.row.symbol)">
												{{$t('indexbtn')}}
											</el-button>
										</template>
									</el-table-column>
								</el-table>
							</div>
						</el-tab-pane>
						
					</el-tabs>
				</div>
			</div>
		</div>
		<div class="index-section">
			<div class="container">
				<div class="index-grid flex">
					<div class="grid-photo">
						<img src="../../assets/photo/web/pic2.png" alt="" class="wow fadeInRight animated" data-wow-duration="1s" data-wow-delay="0.3s">
					</div>
					<div class="flex_bd">
						<ul class="bk-list">
							<li class="wow fadeInUp animated" data-wow-duration="1s" data-wow-delay="0.3s">
								<div class="bk-ico"><img src="../../assets/photo/web/bk1.svg" alt=""></div>
								<div class="flex_bd">
									<h3>{{$t('indexh5')}}</h3>
									<p>{{$t('indexp3')}}</p>
								</div>
							</li>
							<li class="wow fadeInUp animated" data-wow-duration="1s" data-wow-delay="0.4s">
								<div class="bk-ico"><img src="../../assets/photo/web/bk2.svg" alt=""></div>
								<div class="flex_bd">
									<h3>{{$t('indexh6')}}</h3>
									<p>{{$t('indexp4')}}</p>
								</div>
							</li>
							<li class="wow fadeInUp animated" data-wow-duration="1s" data-wow-delay="0.5s">
								<div class="bk-ico"><img src="../../assets/photo/web/bk3.svg" alt=""></div>
								<div class="flex_bd">
									<h3>{{$t('indexh7')}}</h3>
									<p>{{$t('indexp5')}}</p>
								</div>
							</li>
							<li class="wow fadeInUp animated" data-wow-duration="1s" data-wow-delay="0.6s">
								<div class="bk-ico"><img src="../../assets/photo/web/bk4.svg" alt=""></div>
								<div class="flex_bd">
									<h3>{{$t('indexh8')}}</h3>
									<p>{{$t('indexp6')}}</p>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="index-section">
			<div class="container">
				<div class="index-download flex">
					<div class="flex_bd">
						<div class="wow fadeInLeft animated dw-photo" data-wow-duration="1s" data-wow-delay="0.4s"><img src="../../assets/photo/web/dw.png" alt=""></div>
					</div>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import countDown from '@/components/countDown'
   import {gettickerslist} from '@/api/api/bianapi'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	import { WOW } from 'wowjs'
	import Typed from 'typed.js'
	export default {
		name: 'Home',
		components: {
			Head,
			Foot,
			// lineChart,
			// lineChart2,
			countDown
		},
		data() {
			return {
				endTime:new Date("2025/10/01 08:00:20").getTime() / 1000 +  ' ',
			//  console.log(endTime)=>得到毫秒  1658030420 可在方法里面放入后台数据,new Date('放后台数据').getTime() / 1000 +  ' ',但要记得调用方法
			endText:'',
				windowWidth :'true',
				count: '', //倒计时
        		seconds: 18*24*30*60*60, // 10天的秒数
				activeName: 'USDT',
				searchVal: '',
				findData: [],
				tableData: [],
				tableData1: [],
				tableData2: [],
				old: [],
				old2: [],
				old3: [],
				old4: [],
				copy: {
					findData: [],
					tableData: [],
					tableData1: [],
					tableData2: []
				},
				lineData: {},
				user: {},
				isLogin: false,
				allData: [],
				adList: [],
				timer:null,
				tickers: [],
			};
		},
		mounted() {
			this.getUser()
			this.getAdv()
			this.Time() //调用定时器
			setTimeout(()=>{
				let wow = new WOW({
				boxClass: 'wow',
				animateClass: 'animated',
				offset: 0,
				mobile: true,
				live: false,
			})
			wow.init()
			new Typed('#element', {
				strings: [this.$t('indexTxt2')],
				typeSpeed: 100,
			})
			})
			
		},
		computed: {
			searchInput: {
				get() {
					return this.searchVal;
				},
				set(val) {
					this.searchVal = val.toUpperCase();
				}
			}
		},
		created() {
			if(window.innerWidth<700)
			{
				this.windowWidth=false;
			}
			   this.gitticker();
       },
		methods: {
			toUrl(url) {
				this.$router.push(url)
			},
			 gitticker(){
                // this.isLogin = this.$store.state.isLogin
                // const symbols=["BTCUSDT","ETHUSDT","BNBUSDT","XRPUSDT","BCHUSDT","SOLUSDT","ETCUSDT","ADAUSDT","DOTUSDT","AVAXUSDT","TRXUSDT","LTCUSDT","ICPUSDT"]
                // for (var i = 0; i < symbols.length; i++) { 
                //      gettickers(symbols[i]).then(response=>{
                //         this.tickers.push({symbol:response.symbol,lastPrice:parseFloat(response.lastPrice).toFixed(6),priceChangePercent: response.priceChangePercent,highPrice:parseFloat(response.highPrice).toFixed(2),lowPrice:parseFloat(response.lowPrice).toFixed(2),volume:parseFloat(response.volume).toFixed(2),quoteVolume:parseFloat(response.quoteVolume).toFixed(2)}); 
                //      })
                //   } 
				  this.isLogin = this.$store.state.isLogin
                	const symbols="[\"BTCUSDT\",\"ETHUSDT\",\"BNBUSDT\",\"XRPUSDT\",\"BCHUSDT\",\"SOLUSDT\",\"ETCUSDT\",\"ADAUSDT\",\"DOTUSDT\",\"AVAXUSDT\",\"TRXUSDT\",\"LTCUSDT\",\"ICPUSDT\"]"
                      gettickerslist(symbols).then(response=>{
                        for(var i=0;i< response.length;i++){
                           this.tickers.push({symbol:response[i].symbol,lastPrice:parseFloat(response[i].lastPrice).toFixed(6),priceChangePercent: response[i].priceChangePercent,highPrice:parseFloat(response[i].highPrice).toFixed(2),lowPrice:parseFloat(response[i].lowPrice).toFixed(2),volume:parseFloat(response[i].volume).toFixed(2),quoteVolume:parseFloat(response[i].quoteVolume).toFixed(2)}); 
                        }
                     });
                       
            },
			toExchange(val) {
				//var str = val.toLowerCase()
    			// const splitIndex = str.length - 4;
      			// const symbol= str.slice(0, splitIndex) + '_' + str.slice(splitIndex);
				this.$router.push({
					path: '/exchange/' + val
				})
			},
		}
	}
</script>
<style scoped>
::v-deep .typed-cursor{
	display: none !important;
}
.index-download .flex_bd{
	padding-right: 80px;
}
@media (max-width: 700px) {
	.index-download .flex_bd{
	padding-right: 0;
}
}
  .text {
    font-size: 14px;
  }

  .item {
    padding: 18px 0;
  }

  .box-card {
	border-style: none;
    width: 400px;
	margin: 30px;
	margin-bottom: 40px;
	margin-right: 20px;
	background-color:rgba(201, 200, 200, 0);
	color: #ffffff;
  }

</style>
